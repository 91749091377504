import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import classes from './filters.module.sass';
import { Button, Grid, Stack } from '@mui/material';
import Input from 'src/components/input';
import Select from 'src/components/select';
import Checkbox from 'src/components/checkbox';
import useResponsive from 'src/hooks/useResponsive';
import TagFilter from 'src/components/filters/TagFilter';

export default function UserFilters({ onSubmit }) {
  const isDesktop = useResponsive();
  const location = useLocation();

  const countries = useSelector((state) => state.application.countries);
  const roles = useSelector((state) => state.application.roles);

  const query = qs.parse(location.search);
  const initialValues = {
    ...query,
    tags: (typeof query.tags === 'string' ? query.tags?.split(',') : []) ?? [],
    citizenship: countries.find((c) => c.code === parseInt(query.citizenship, 10))?.code,
    countryResidence: countries.find((c) => c.code === parseInt(query.countryResidence, 10))?.code,
    mainRoleId: roles.find((c) => c.id === query.mainRoleId)?.id,
  };
  const handleSubmit = (filters) => {
    let baseFilter = { ...filters };
    ['hasDocument', 'hasAvatar', 'isGivenMerch', 'isBadgePrinted'].forEach((key) => {
      if (!baseFilter[key]) {
        delete baseFilter[key];
      }
    });

    return onSubmit({
      ...baseFilter,
      tags: filters.tags?.join(','),
      citizenship: filters.citizenship,
      countryResidence: filters.countryResidence,
      mainRoleId: filters.mainRoleId,
    });
  };
  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.filters}>
          <Stack gap={isDesktop ? '36px' : '24px'}>
            <Grid container spacing={12}>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  <Input name="lastName" label="Фамилия" />
                  <Input name="firstName" label="Имя" />
                  <Input name="middleName" label="Отчество" />
                  <Input name="email" label="Email" type="email" />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  {/* <Select
                    name="citizenship"
                    label="Гражданство"
                    options={countries}
                    getOptionLabel={(item) => item.localization?.ru_RU?.name}
                    getOptionValue={(item) => item.code}
                    isClearable
                  /> */}
                  <Input name="phone" label="Телефон" />
                  <Input name="companyName" label="Название компании" />
                  {/* <Input name="uuid" label="Uuid" /> */}
                  <Input name="transactionNumber" label="Номер транзакции" />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  {/* <Select
                    name="countryResidence"
                    label="Страна проживания/работы"
                    options={countries}
                    getOptionLabel={(item) => item.localization?.ru_RU?.name}
                    getOptionValue={(item) => item.code}
                    isClearable
                  />
                  <Input name="externalId" placeholder="ICM number" /> */}
                  <Select
                    name="mainRoleId"
                    label="Роль"
                    options={roles}
                    getOptionLabel={(item) => item.name?.ru_RU}
                    getOptionValue={(item) => item.id}
                    isClearable
                  />
                  <TagFilter />
                  <Stack gap="8px">
                    <Checkbox name="hasDocuments" label="Есть документы" />
                    <Checkbox name="hasAvatar" label="Есть аватарка" />
                    <Checkbox name="isGivenMerch" label="Пользователь уже получил мерч" />
                    <Checkbox name="isBadgePrinted" label="Бейдж был напечатан" />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={12}>
              <Grid item xs={12} md={12} lg={4}></Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={() => {
                    form.restart();
                    onSubmit({});
                  }}
                >
                  Очистить фильтры
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button type="submit" variant="contained" className={classes.button}>
                  Искать
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      )}
    </Form>
  );
}
