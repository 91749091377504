import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowDownward, ArrowUpward, AttachFile, Close, Delete } from '@mui/icons-material';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { SelectControllerHookForm } from 'src/@core/components/mui/select';
import { LanguageMuiSelect } from 'src/components/language-select';
import { autoFillObjectValues, getLocalizationAnketaFields } from 'src/utils/common';
import { LOCALIZATION } from 'src/utils/constant';
import eventBus, { EVENTS } from 'src/utils/eventBus';
import { validatorMessage } from 'src/utils/formValidator';
import { createErrorNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import * as yup from 'yup';
import classes from './anketa.module.sass';
import { FIELD_TYPES, getLocalizationAnketaFieldsData } from './AnketaAddField';
import AnketaOptionField from './AnketaOptionField';
import { documentAPIs } from 'src/services';
import { CONSENT_PERSONAL_DATA_NAME, DOCUMENT_TYPES } from 'src/constants';
import ButtonLoading from 'src/components/button-loading';

const schema = yup.object().shape({
  name: yup
    .string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .max(512, validatorMessage.MAX_CHARACTER.replace('{max}', 512)),
});

function AnketaField({
  projectUuid,
  anketaUuid,
  fieldsLength,
  field,
  isDisabledEdit,
  refetchAnketaData,
  onMoveFieldUp,
  onMoveFieldDown,
  onPatchAnketa,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isRequired, setIsRequired] = useState(field.required);
  const [isHidden, setIsHidden] = useState(field.isHidden);
  const [isUsedForSelfRegsiter, setIsUsedForSelfRegsiter] = useState(field.isUsedForSelfRegsiter);
  const [options, setOptions] = useState([]);
  const [lang, setLang] = useState(LOCALIZATION.ru_RU);
  const [cacheValues, setCacheValues] = useState({});
  const [optionsError, setOptionsError] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isFetchingDocument, setIsFetchingDocument] = useState(false);

  const anketaFieldSettings = useMemo(() => field?.AnketaFieldSettings || {}, [field]);
  const isConsentPersonalDataField = useMemo(() => {
    return (
      anketaFieldSettings?.typeInput === 'yes_no' &&
      (anketaFieldSettings?.name === CONSENT_PERSONAL_DATA_NAME ||
        anketaFieldSettings?.localization?.ru_RU?.name === CONSENT_PERSONAL_DATA_NAME ||
        anketaFieldSettings?.localization?.en_US?.name === CONSENT_PERSONAL_DATA_NAME)
    );
  }, [anketaFieldSettings]);

  const {
    reset,
    register,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        name: anketaFieldSettings.name,
        typeInput: FIELD_TYPES[0].value,
        badgeName: '',
        defaultText: '',
        subText: '',
        hintText: '',
        length: 256,
      }),
      [field]
    ),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isConsentPersonalDataField && isEditing) {
      const fetchDocument = async () => {
        try {
          setIsFetchingDocument(true);
          const res = await documentAPIs.getDocuments({ type: DOCUMENT_TYPES.CONSENT_PERSONAL_DATA, projectUuid });
          const document = res?.message?.[0];
          if (document) {
            const resBlob = await documentAPIs.getDocumentFile(res.message[0].uuid);
            if (resBlob) {
              resBlob.name = CONSENT_PERSONAL_DATA_NAME;
              setCurrentFile(resBlob);
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetchingDocument(false);
        }
      };
      fetchDocument();
    }
  }, [isConsentPersonalDataField, isEditing, projectUuid]);

  useEffect(() => {
    setIsRequired(field.required);
    setIsHidden(field.isHidden);
    setIsUsedForSelfRegsiter(field.isUsedForSelfRegsiter);
    setLang(LOCALIZATION.ru_RU);
  }, [isEditing, field]);

  useEffect(() => {
    setOptionsError('');
  }, [options]);

  const handleDeleteAnketaField = (field) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: async () => {
        try {
          await request(`/internal/anketas/${anketaUuid}/fields/${field.uuid}`, {
            method: 'DELETE',
          });
          refetchAnketaData();
        } catch (error) {
          console.error(error);
          toast.error(error.message?.error);
        }
      },
    });
  };

  useEffect(() => {
    reset(anketaFieldSettings);
    const options = anketaFieldSettings.options || [];
    setCacheValues({ ...(anketaFieldSettings?.localization || {}) });
    setOptions(options);
  }, [isEditing, anketaFieldSettings]);

  const handleUpload = async (e) => {
    try {
      if (!isConsentPersonalDataField) return;
      let body = new FormData();
      body.append('fileDocument', currentFile);
      body.append(
        'document',
        JSON.stringify({
          typeDoc: DOCUMENT_TYPES.CONSENT_PERSONAL_DATA,
          projectUuid: projectUuid,
          fileName: fileName || currentFile.name,
          contentType: currentFile.type,
        })
      );
      await documentAPIs.uploadDocument(body);
    } catch (error) {
      console.error(error);
    } finally {
      setCurrentFile(null);
      setFileName('');
    }
  };

  const handleUpdateField = async (data) => {
    const newData = { ...field, ...data };
    const { position } = newData;

    const { optionsError, newInfoValues, localization } = getLocalizationAnketaFieldsData(
      lang,
      cacheValues,
      options,
      newData
    );
    if (optionsError) return setOptionsError(optionsError);

    await Promise.all([
      onPatchAnketa({
        [field.uuid]: {
          required: isRequired,
          isHidden: isHidden,
          isUsedForSelfRegsiter: isUsedForSelfRegsiter,
          position,
          name: newInfoValues.name,
          typeInput: newInfoValues.typeInput,
          length: parseInt(newInfoValues.length),
          badgeName: newInfoValues.badgeName,
          defaultText: newInfoValues.defaultText,
          subText: newInfoValues.subText,
          hintText: newInfoValues.hintText,
          options: localization[LOCALIZATION.ru_RU].options,
          localization,
          // ...(options?.length > 0 ? { options } : ''),
          // ...(badgeName ? { badgeName } : ''),
          // ...(defaultText ? { defaultText } : ''),
          // ...(subText ? { subText } : ''),
          // ...(hintText ? { hintText } : ''),
        },
      }),
      handleUpload(),
    ]);

    setIsEditing(false);
    setLang(LOCALIZATION.ru_RU);
  };

  const handleSetLang = (lang) => {
    const currentValues = Object.assign(getValues() || {}, { options });
    const currentCaches = { ...cacheValues };
    setCacheValues((old) => ({
      ...old,
      [lang === LOCALIZATION.ru_RU ? LOCALIZATION.en_US : LOCALIZATION.ru_RU]:
        getLocalizationAnketaFields(currentValues),
    }));
    const newValues = {
      ...{ typeInput: currentValues.typeInput, length: currentValues.length },
      ...getLocalizationAnketaFields(currentCaches[lang] || {}),
    };
    Object.keys(newValues).forEach((key) => setValue(key, newValues[key]));
    reset(newValues);
    setOptions(newValues.options || []);
    setLang(lang);
  };

  const currentFileURL = useMemo(() => {
    if (!currentFile) return '';
    const urlCreator = window.URL || window.webkitURL;
    const fileURL = urlCreator.createObjectURL(currentFile);
    return fileURL;
  }, [currentFile]);

  return (
    <Card key={field.uuid}>
      <Stack>
        <Stack
          direction="row"
          gap="16px"
          justifyContent="space-between"
          paddingX="20px"
          paddingY="6px"
          bgcolor="#EDEDF3"
        >
          <Stack direction="row" flexWrap="wrap" gap="10px" sx={{ overflowWrap: 'anywhere' }}>
            <Typography
              variant="body2"
              lineHeight="24px"
              fontWeight={500}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              Поле шаблона анкеты:
            </Typography>
            <Typography
              variant="body2"
              lineHeight="24px"
              fontWeight={300}
              sx={{ color: (theme) => theme.palette.text.primary }}
            >
              {field.AnketaFieldSettings?.name}
            </Typography>
          </Stack>

          <Stack direction="row" gap="10px" sx={{ height: 'fit-content' }}>
            <Stack direction="row">
              <IconButton sx={{ padding: 0, borderRadius: 0 }} disabled={field.position === 1} onClick={onMoveFieldUp}>
                <ArrowUpward />
              </IconButton>
              <IconButton
                sx={{ padding: 0, borderRadius: 0 }}
                disabled={field.position === fieldsLength}
                onClick={onMoveFieldDown}
              >
                <ArrowDownward />
              </IconButton>
            </Stack>
            <IconButton
              sx={{ padding: 0, borderRadius: 0, opacity: isEditing ? 0.6 : 1 }}
              onClick={() => setIsEditing(!isEditing)}
            >
              <SettingsApplicationsIcon />
            </IconButton>
            <IconButton sx={{ padding: 0, borderRadius: 0 }} onClick={() => handleDeleteAnketaField(field)}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
        <CardContent>
          {!isEditing ? (
            <Stack>
              <Grid container rowSpacing={4} columnSpacing={6}>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="заголовок" size="small" value={anketaFieldSettings?.name} />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Typography
                    component="p"
                    variant="caption"
                    lineHeight="18px"
                    sx={{ color: (theme) => theme.palette.text.primary }}
                  >{`Для вставки ссылок используйте формат: {текст_ссылки}`}</Typography>
                </Grid> */}
              </Grid>
            </Stack>
          ) : (
            <form onSubmit={handleSubmit(handleUpdateField)}>
              <Stack>
                <Grid container rowSpacing={4} columnSpacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="заголовок"
                      size="small"
                      {...register('name')}
                      error={errors.name}
                      helperText={errors.name?.message}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <Typography
                      component="p"
                      variant="caption"
                      lineHeight="18px"
                      sx={{ color: (theme) => theme.palette.text.primary }}
                    >{`Для вставки ссылок используйте формат: {текст_ссылки}`}</Typography>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <LanguageMuiSelect lang={lang} setLang={handleSetLang} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                    <Stack>
                      <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.primary }}>
                        Настройки поля:
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack gap="16px">
                      <Controller
                        name="typeInput"
                        control={control}
                        render={({ field }) => (
                          <SelectControllerHookForm
                            label="специальный тип поля"
                            fieldForm={field}
                            disabled={isDisabledEdit}
                          >
                            {FIELD_TYPES.map((option, i) => (
                              <MenuItem key={`typeInput-${i}`} value={option.value}>
                                <>{option.label}</>
                              </MenuItem>
                            ))}
                          </SelectControllerHookForm>
                        )}
                      />

                      <AnketaOptionField
                        label="варианты выбора"
                        options={options.map((option) => ({ inputValue: option, title: option }))}
                        setOptions={(options) => setOptions(options.map((option) => option.inputValue))}
                        // disabled={isDisabledEdit}
                        error={optionsError}
                      />
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="имя переменной бейджа"
                        {...register('badgeName')}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack gap="16px">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="по-умолчанию"
                        {...register('defaultText')}
                      />
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="текст заглушки"
                        {...register('subText')}
                      />
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="текст подсказки"
                        {...register('hintText')}
                      />
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        fullWidth
                        label="длина"
                        {...register('length')}
                      />
                    </Stack>
                  </Grid>
                  {isConsentPersonalDataField && (
                    <Grid item xs={12}>
                      <Stack direction="row" gap="8px" alignItems="center">
                        <ButtonLoading
                          startIcon={
                            isFetchingDocument ? <CircularProgress size="12px" color="inherit" /> : <AttachFile />
                          }
                          variant="contained"
                          component="label"
                          sx={{ width: 'fit-content' }}
                          isLoading={isFetchingDocument}
                          disabled={isFetchingDocument}
                        >
                          Загрузите документ
                          <input
                            hidden
                            type="file"
                            onChange={(e) => {
                              const newFile = e.target.files[0];
                              const fileNameArr = newFile.name.split('.');
                              if (!fileName) {
                                setFileName(fileNameArr.slice(0, fileNameArr.length - 1).join(''));
                              }
                              setCurrentFile(newFile);
                            }}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                          />
                        </ButtonLoading>
                        {currentFile && (
                          <>
                            <Link href={currentFileURL} target="_blank" sx={{ cursor: 'pointer' }}>
                              {currentFile.name}
                            </Link>
                            <IconButton onClick={() => setCurrentFile(null)}>
                              <Delete />
                            </IconButton>
                          </>
                        )}
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isRequired}
                          onChange={(e) => {
                            setIsRequired(e.target.checked);
                          }}
                          color="primary"
                          // disabled={isDisabledEdit}
                        />
                      }
                      label="Обязательно"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isHidden}
                          onChange={(e) => {
                            setIsHidden(e.target.checked);
                          }}
                          color="primary"
                          // disabled={isDisabledEdit}
                        />
                      }
                      label="Скрыть в интерфейсе ЛК"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isUsedForSelfRegsiter}
                          onChange={(e) => {
                            setIsUsedForSelfRegsiter(e.target.checked);
                          }}
                          color="primary"
                          // disabled={isDisabledEdit}
                        />
                      }
                      label="Используется для самостоятельной регистрации анкеты"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack gap="16px" direction="row" sx={{ '& > *': { flex: '1 1 50%' } }}>
                      <Button type="submit" variant="contained">
                        Ok
                      </Button>
                      <Button variant="outlined" onClick={() => setIsEditing(false)}>
                        Отмена
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          )}
        </CardContent>
      </Stack>
    </Card>
  );
}

export default AnketaField;
