import { AbilityBuilder, createMongoAbility } from '@casl/ability';

export const ACCESSES = {
  ACCREDITE_USER: 'accredite_user',
  BLOCK_USER: 'block_user',
  CHANGE_ROLE: 'change_role',
  CREATE_USER: 'create_user',
  DOWNLOAD_REPORT: 'download_report',
  EDIT_COMPANY: 'edit_company',
  EDIT_PACKAGE: 'edit_package',
  EDIT_SETTINGS: 'edit_settings',
  SEE_ORDER: 'see_order',
  EDIT_USER: 'edit_user',
  EDIT_VIZIT: 'edit_vizit',
  MANAGE_BDK: 'manage_bdk',
  MANAGE_MEETING: 'manage_meeting',
  MANAGE_VIZIT: 'manage_vizit',
  PRINT_BADGE: 'print_badge',
  REPEAT_PRINT_BADGE: 'repeat_print_badge',
  SEE_FIELD_ANKETA: 'see_field_anketa',
  SEE_PROFILE: 'see_profile',
  SYNC_THEZIS: 'sync_thezis',
  EDIT_PART_ANKETA: 'edit_part_anketa',
  BLOCK_FROM_SKUD: 'block_from_skud',
  UNBLOCK_REPRINT_BADGE: 'unblock_reprint_badge',
  BLOCK_AND_GEN_NEW_BADGE: 'block_and_gen_new_badge',
  EDIT_LOCKED_ANKETA: 'edit_locked_anketa'
};

export function updateAbility(ability, accesses = {}) {
  const { can, cannot, rules } = new AbilityBuilder(ability);

  Object.keys(accesses).forEach((action) => {
    if (accesses[action]) {
      can(action);
    } else {
      cannot(action);
    }
  });

  ability.update(rules);
}

function defineAbilitiesFor() {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  Object.values(ACCESSES).forEach((action) => {
    can(action);
  });

  return build();
}

const ability = defineAbilitiesFor();

export default ability;
