import { yupResolver } from '@hookform/resolvers/yup';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import {
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { renderMultiSelect, SelectHookForm } from 'src/@core/components/mui/select';
import { request } from 'src/utils/request';
import classes from './anketa.module.sass';
import * as yup from 'yup';
import AnketaOptionField from './AnketaOptionField';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { validatorMessage } from 'src/utils/formValidator';
import { LanguageMuiSelect } from 'src/components/language-select';
import { LOCALIZATION } from 'src/utils/constant';
import { autoFillObjectValues, getLocalizationAnketaFields } from 'src/utils/common';
import { CONSENT_PERSONAL_DATA_NAME, DOCUMENT_TYPES } from 'src/constants';
import { documentAPIs } from 'src/services';
import { AttachFile, Delete } from '@mui/icons-material';
import ButtonLoading from 'src/components/button-loading';

export const FIELD_TYPES = [
  { value: 'text', label: 'текстовый ввод' },
  { value: 'long_text', label: 'многострочный текст' },
  { value: 'email', label: 'Email' },
  { value: 'telephone', label: 'номер телефона' },
  { value: 'number', label: 'число' },
  { value: 'url', label: 'URL' },
  { value: 'yes_no', label: 'выбор да/нет' },
  { value: 'checkboxes', label: 'множественный выбор (галки)' },
  { value: 'popup_list', label: 'выпадающий список' },
  { value: 'popup_list_checkboxes', label: 'множественный выбор (выпадающий список)' },
  { value: 'radio_buttons', label: 'радио-кнопки' },
  { value: 'upload_file', label: 'загрузка файлов' },
  { value: 'data', label: 'дата' },
  { value: 'datetime', label: 'дата и время' },
  { value: 'list_input', label: 'список+ввод' },
];

export const FIELD_TYPE_REQUIRE_OPTIONS = [
  'checkboxes',
  'popup_list',
  'popup_list_checkboxes',
  'radio_buttons',
  'list_input',
];

const schema = yup.object().shape({
  name: yup
    .string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .max(512, validatorMessage.MAX_CHARACTER.replace('{max}', 512)),
});

export const getLocalizationAnketaFieldsData = (currentLang, cacheValues, options, formValues) => {
  const cacheValuesRu = getLocalizationAnketaFields(cacheValues[LOCALIZATION.ru_RU]);
  const cacheValuesEn = getLocalizationAnketaFields(cacheValues[LOCALIZATION.en_US]);

  const isRequireOptions = FIELD_TYPE_REQUIRE_OPTIONS.includes(formValues?.AnketaFieldSettings?.typeInput);
  const optionsRu = currentLang === LOCALIZATION.ru_RU ? options : cacheValuesRu?.options;
  const optionsEn = currentLang === LOCALIZATION.en_US ? options : cacheValuesEn?.options;
  if (isRequireOptions && optionsRu?.length !== optionsEn?.length) {
    return { optionsError: 'Параметры английская и русская версии должны быть одинаковой длины' };
  }

  const localization = {
    [LOCALIZATION.en_US]: getLocalizationAnketaFields(
      Object.assign(
        currentLang === LOCALIZATION.en_US
          ? autoFillObjectValues(formValues, cacheValuesRu)
          : autoFillObjectValues(cacheValuesEn, formValues),
        {
          options: currentLang === LOCALIZATION.en_US ? options : cacheValuesEn?.options,
        }
      )
    ),
    [LOCALIZATION.ru_RU]: getLocalizationAnketaFields(
      Object.assign(
        currentLang === LOCALIZATION.ru_RU
          ? autoFillObjectValues(formValues, cacheValuesEn)
          : autoFillObjectValues(cacheValuesRu, formValues),
        {
          options: currentLang === LOCALIZATION.ru_RU ? options : cacheValuesRu?.options,
        }
      )
    ),
  };
  const newInfoValues =
    currentLang === LOCALIZATION.ru_RU
      ? formValues
      : {
          ...formValues,
          ...localization[LOCALIZATION.ru_RU],
        };
  return { newInfoValues, localization };
};

function AnketaAddField({ projectUuid, roleUuid, anketaUuid, anketaFields, setShowAddNewField, refetchAnketaData }) {
  const {
    reset,
    register,
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      typeInput: FIELD_TYPES[0].value,
      badgeName: '',
      defaultText: '',
      subText: '',
      hintText: '',
      length: 256,
    },
    resolver: yupResolver(schema),
  });

  const [lang, setLang] = useState(LOCALIZATION.ru_RU);
  const [cacheValues, setCacheValues] = useState({});
  const [options, setOptions] = useState([]);
  const [optionsError, setOptionsError] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isFetchingDocument, setIsFetchingDocument] = useState(false);

  const [typeInput, name] = useWatch({ control, name: ['typeInput', 'name'] });
  const isConsentPersonalDataField = useMemo(() => {
    return (
      typeInput === 'yes_no' &&
      (name === CONSENT_PERSONAL_DATA_NAME ||
        cacheValues?.ru_RU?.name === CONSENT_PERSONAL_DATA_NAME ||
        cacheValues?.en_US?.name === CONSENT_PERSONAL_DATA_NAME)
    );
  }, [typeInput, name, cacheValues]);
  const currentFileURL = useMemo(() => {
    if (!currentFile) return '';
    const urlCreator = window.URL || window.webkitURL;
    const fileURL = urlCreator.createObjectURL(currentFile);
    return fileURL;
  }, [currentFile]);

  useEffect(() => {
    setOptionsError('');
  }, [options]);

  useEffect(() => {
    if (isConsentPersonalDataField) {
      const fetchDocument = async () => {
        try {
          setIsFetchingDocument(true);
          const res = await documentAPIs.getDocuments({ type: DOCUMENT_TYPES.CONSENT_PERSONAL_DATA, projectUuid });
          const document = res?.message?.[0];
          if (document) {
            const resBlob = await documentAPIs.getDocumentFile(res.message[0].uuid);
            if (resBlob) {
              resBlob.name = CONSENT_PERSONAL_DATA_NAME;
              setCurrentFile(resBlob);
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetchingDocument(false);
        }
      };
      fetchDocument();
    }
  }, [isConsentPersonalDataField, projectUuid]);

  const handleUpload = async (e) => {
    try {
      if (!isConsentPersonalDataField) return;
      let body = new FormData();
      body.append('fileDocument', currentFile);
      body.append(
        'document',
        JSON.stringify({
          typeDoc: DOCUMENT_TYPES.CONSENT_PERSONAL_DATA,
          projectUuid: projectUuid,
          fileName: fileName || currentFile.name,
          contentType: currentFile.type,
        })
      );
      await documentAPIs.uploadDocument(body);
    } catch (error) {
      console.error(error);
    } finally {
      setCurrentFile(null);
      setFileName('');
    }
  };

  const handleAddNewField = async (data) => {
    try {
      const numOfFields = anketaFields?.length || 0;
      const position = numOfFields + 1;
      const length = parseInt(data?.length);

      const { optionsError, newInfoValues, localization } = getLocalizationAnketaFieldsData(
        lang,
        cacheValues,
        options,
        data
      );
      if (optionsError) return setOptionsError(optionsError);

      if (numOfFields === 0) {
        // Add anketa
        await request('/internal/anketas/add', {
          method: 'POST',
          body: {
            projectUuid: projectUuid,
            roleUuid: roleUuid,
            fields: [
              {
                ...newInfoValues,
                localization,
                fieldTemplateUuid: '',
                position: 1,
                options: localization[LOCALIZATION.ru_RU].options,
                length,
              },
            ],
          },
        });
      } else {
        // Add anketa field
        await request(`/internal/anketas/${anketaUuid}`, {
          method: 'POST',
          body: {
            ...newInfoValues,
            localization,
            fieldTemplateUuid: '',
            position: position,
            options: localization[LOCALIZATION.ru_RU].options,
            length,
          },
        });
      }
      await handleUpload();
      refetchAnketaData();
      setShowAddNewField(false);
    } catch (error) {
      console.error(error);
      toast.error(error.message?.error);
      createNotification('Что-то пошло не так', 'error');
    }
  };

  const handleSetLang = (lang) => {
    const currentValues = Object.assign(getValues() || {}, { options });
    const currentCaches = { ...cacheValues };
    setCacheValues((old) => ({
      ...old,
      [lang === LOCALIZATION.ru_RU ? LOCALIZATION.en_US : LOCALIZATION.ru_RU]:
        getLocalizationAnketaFields(currentValues),
    }));
    const newValues = {
      ...{ typeInput: currentValues.typeInput, length: currentValues.length },
      ...getLocalizationAnketaFields(currentCaches[lang] || {}),
    };
    Object.keys(newValues).forEach((key) => setValue(key, newValues[key]));
    reset(newValues);
    setOptions(newValues.options || []);
    setLang(lang);
  };

  return (
    <form onSubmit={handleSubmit(handleAddNewField)}>
      <Card>
        <Stack>
          <Stack
            direction="row"
            gap="16px"
            justifyContent="space-between"
            paddingX="20px"
            paddingY="6px"
            bgcolor="#EDEDF3"
          >
            <Stack direction="row" flexWrap="wrap" gap="10px">
              <Typography
                variant="body2"
                lineHeight="24px"
                fontWeight={500}
                sx={{ color: (theme) => theme.palette.text.primary }}
              >
                Поле шаблона анкеты:
              </Typography>
            </Stack>

            <Stack direction="row" gap="10px">
              <IconButton sx={{ padding: 0, borderRadius: 0 }} type="html">
                <Check />
              </IconButton>
              <IconButton sx={{ padding: 0, borderRadius: 0 }} onClick={() => setShowAddNewField(false)}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
          <CardContent>
            <Stack>
              <Grid container rowSpacing={4} columnSpacing={6}>
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="заголовок"
                    size="small"
                    {...register('name')}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LanguageMuiSelect lang={lang} setLang={handleSetLang} />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Typography
                    component="p"
                    variant="caption"
                    lineHeight="18px"
                    sx={{ color: (theme) => theme.palette.text.primary }}
                  >{`Для вставки ссылок используйте формат: {текст_ссылки}`}</Typography>
                </Grid> */}
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                  <Stack>
                    <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.primary }}>
                      Настройки поля:
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack gap="16px">
                    <SelectHookForm
                      label="специальный тип поля"
                      selectProps={{ ...register('typeInput'), defaultValue: FIELD_TYPES[0].value }}
                    >
                      {FIELD_TYPES.map((option, i) => (
                        <MenuItem key={`typeInput-${i}`} value={option.value}>
                          <>{option.label}</>
                        </MenuItem>
                      ))}
                    </SelectHookForm>
                    <AnketaOptionField
                      label="варианты выбора"
                      options={options.map((option) => ({ inputValue: option, title: option }))}
                      setOptions={(options) => setOptions(options.map((option) => option.inputValue))}
                      error={optionsError}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="имя переменной бейджа"
                      {...register('badgeName')}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack gap="16px">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="по-умолчанию"
                      {...register('defaultText')}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="текст заглушки"
                      {...register('subText')}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="текст подсказки"
                      {...register('hintText')}
                    />
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      fullWidth
                      label="длина"
                      {...register('length')}
                    />
                  </Stack>
                </Grid>
                {isConsentPersonalDataField && (
                  <Grid item xs={12}>
                    <Stack direction="row" gap="8px" alignItems="center">
                      <ButtonLoading
                        startIcon={
                          isFetchingDocument ? <CircularProgress size="12px" color="inherit" /> : <AttachFile />
                        }
                        variant="contained"
                        component="label"
                        sx={{ width: 'fit-content' }}
                        isLoading={isFetchingDocument}
                        disabled={isFetchingDocument}
                      >
                        Загрузите документ
                        <input
                          hidden
                          type="file"
                          onChange={(e) => {
                            const newFile = e.target.files[0];
                            const fileNameArr = newFile.name.split('.');
                            if (!fileName) {
                              setFileName(fileNameArr.slice(0, fileNameArr.length - 1).join(''));
                            }
                            setCurrentFile(newFile);
                          }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </ButtonLoading>
                      {currentFile && (
                        <>
                          <Link href={currentFileURL} target="_blank" sx={{ cursor: 'pointer' }}>
                            {currentFile.name}
                          </Link>
                          <IconButton onClick={() => setCurrentFile(null)}>
                            <Delete />
                          </IconButton>
                        </>
                      )}
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox {...register('required')} color="primary" />}
                    label="Обязательно"
                  />
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </Stack>
      </Card>
    </form>
  );
}

export default AnketaAddField;
